
export const mathematicsPortfolio = [
    {
        id: 1,
        title: "Fractal Generation",
        img: "/assets/mandlebrot.png",
        link: "https://github.com/diazjacob/Fractal-Generation"
    }
];
export const systemsPortfolio = [
    {
        id: 1,
        title: "TamaPi",
        img: "/assets/tamapi.png",
        link: "https://github.com/diazjacob/TamaPi"
    },
    {
        id: 2,
        title: "Stock Bot",
        img: "/assets/stockbot.png",
        link: "https://github.com/diazjacob/Stock-Bot"
    }
];
export const gamedevPortfolio = [
    {
        id: 1,
        title: "Mobile Game",
        img: "/assets/jetdemo.gif",
        link: "https://github.com/diazjacob/balloons"
    },
    {
        id: 2,
        title: "My First Game Jam (2016)",
        img: "/assets/ludumdare.png",
        link: "https://ldjam.com/events/ludum-dare/38/$27231"
    },
    {
        id: 3,
        title: "VR Prototype",
        img: "/assets/alc.gif",
        link: ""
    },
    {
        id: 3,
        title: "Game Dev Commissions",
        img: "/assets/comissions.gif",
        link: "https://github.com/diazjacob/Commission-Example"
    },
    {
        id: 3,
        title: "Shaders",
        img: "/assets/shader.gif",
        link: "https://github.com/diazjacob/Shader-Example"
    },
];
export const webdevPortfolio = [
    {
        id: 1,
        title: "Multi-Agent Dynamics",
        img: "/assets/boidsgif1.gif",
        link: "https://github.com/diazjacob/boidflocking"
    },
    {
        id: 2,
        title: "Computer Graphics",
        img: "/assets/graphics1.gif",
        link: "https://github.com/diazjacob/computergraphicsA1"
    },
    {
        id: 3,
        title: "Machine Learning",
        img: "/assets/formula.png",
        link: "https://github.com/diazjacob/COMP4360-Machine-Learning"
    }
];
